import { LinkButton } from "../../../../components/button/LinkButton";
import { ISelectOption, Select } from "../../../../editComponents/form/Select/Select";
import { IWizardData } from "../../StartWizard";
import {
    CONTENT_EXAMPLES_CHILDREN,
    CONTENT_EXAMPLES_DRESS_CODES,
    CONTENT_EXAMPLES_GIFTS,
    TContentExampleChildren,
    TContentExampleDressCode,
    TContentExampleGifts,
} from "../../utils/contentExamples";
import { WizardTopText } from "../WizardTopText/WizardTopText";
import styles from "./WizardContent.module.scss";

interface IProps {
    wizardData: IWizardData;
    setWizardData: (data: IWizardData) => void;
}

export function WizardContent(props: IProps) {
    const dressCodeOptions: ISelectOption<TContentExampleDressCode>[] = (
        Object.keys(CONTENT_EXAMPLES_DRESS_CODES) as TContentExampleDressCode[]
    ).map((option) => ({
        title: CONTENT_EXAMPLES_DRESS_CODES[option].title,
        value: option,
    }));

    const childrenOptions: ISelectOption<TContentExampleChildren>[] = (
        Object.keys(CONTENT_EXAMPLES_CHILDREN) as TContentExampleChildren[]
    ).map((option) => ({
        title: CONTENT_EXAMPLES_CHILDREN[option].title,
        value: option,
    }));

    const giftsOptions: ISelectOption<TContentExampleGifts>[] = (
        Object.keys(CONTENT_EXAMPLES_GIFTS) as TContentExampleGifts[]
    ).map((option) => ({
        title: CONTENT_EXAMPLES_GIFTS[option].title,
        value: option,
    }));

    return (
        <div className={styles.wrapper}>
            <WizardTopText title="Innehåll" step={4}>
                Vi har några få frågor om innehållet på sidan så vi kan hjälpa er att komma igång med exempeltexter.
                Alla texter går att ändra senare.
            </WizardTopText>

            <div className={styles.section}>
                <div>
                    <Select
                        label="Barn"
                        handleChange={(value) => props.setWizardData({ ...props.wizardData, children: value })}
                        value={props.wizardData.children}
                        options={childrenOptions}
                        placeholder="Välj ett alternativ"
                        id="children"
                        theme="LIGHT"
                    />
                </div>
                <div>
                    <Select
                        label="Bröllopsgåvor"
                        handleChange={(value) => props.setWizardData({ ...props.wizardData, gifts: value })}
                        value={props.wizardData.gifts}
                        options={giftsOptions}
                        placeholder="Välj ett alternativ"
                        id="gifts"
                        theme="LIGHT"
                    />
                    {props.wizardData.gifts && CONTENT_EXAMPLES_GIFTS[props.wizardData.gifts].description ? (
                        <div className={styles.description}>
                            <strong>{CONTENT_EXAMPLES_GIFTS[props.wizardData.gifts].title}</strong> -{" "}
                            {CONTENT_EXAMPLES_GIFTS[props.wizardData.gifts].description}
                        </div>
                    ) : null}
                </div>
                <div>
                    <Select
                        label="Klädsel"
                        handleChange={(value) => props.setWizardData({ ...props.wizardData, dressCode: value })}
                        value={props.wizardData.dressCode}
                        options={dressCodeOptions}
                        placeholder="Välj ett alternativ"
                        id="dressCode"
                        theme="LIGHT"
                    />
                    {props.wizardData.dressCode &&
                    CONTENT_EXAMPLES_DRESS_CODES[props.wizardData.dressCode].description ? (
                        <div className={styles.description}>
                            <strong>{CONTENT_EXAMPLES_DRESS_CODES[props.wizardData.dressCode].title}</strong> -{" "}
                            {CONTENT_EXAMPLES_DRESS_CODES[props.wizardData.dressCode].description}
                        </div>
                    ) : null}
                </div>
                <div className={styles.firstButtonWrapper}>
                    <LinkButton fullWidth={true} type="primary" to={"/kom-igang/redigera"}>
                        Fortsätt
                    </LinkButton>
                </div>
                <div>
                    <LinkButton fullWidth={true} type="secondary" to={"/kom-igang/redigera"}>
                        Hoppa över frågor
                    </LinkButton>
                </div>
            </div>
        </div>
    );
}
