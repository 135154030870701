import { WizardBasicInfo } from "./components/WizardBasicInfo/WizardBasicInfo";
import { Route, Switch } from "react-router-dom";
import { useState } from "react";
import { IDesignTemplate } from "../../modules/EditDesignTemplate/utils/designTemplates";
import { ILanguage } from "../../interfaces/Site";
import GeneratorEdit from "../GeneratorEdit/GeneratorEdit";
import { getBrandedQuickstartHost } from "../../utils/getBrand";
import { generateWizardSite } from "./utils/generateWizardSite";
import { WizardSelectDesign } from "./components/WizardSelectDesign/WizardSelectDesign";
import { WizardLanguages } from "./components/WizardLanguages/WizardLanguages";
import { WizardContent } from "./components/WizardContent/WizardContent";
import { Header } from "../../../landing/modules/header/Header";
import Footer from "../../../landing/modules/footer/Footer";
import { TContentExampleChildren, TContentExampleDressCode, TContentExampleGifts } from "./utils/contentExamples";
import { logError } from "../../utils/LogError";

export interface IWizardData {
    dateStart?: string;
    dateNotDecided?: boolean;
    location?: string;
    name1?: string;
    name2?: string;
    designTemplate?: IDesignTemplate;
    dressCode?: TContentExampleDressCode;
    children?: TContentExampleChildren;
    gifts?: TContentExampleGifts;
    languages?: ILanguage[];
}

const initWizardData = (): IWizardData => {
    try {
        const data = sessionStorage.getItem("wizardData");
        if (data) {
            return JSON.parse(data);
        }
    } catch (e) {
        // Do nothing.
    }
    return {};
};

export function StartWizard() {
    const [wizardData, setWizardData] = useState<IWizardData>(initWizardData());

    const getWizardSite = () => {
        try {
            return generateWizardSite(wizardData);
        } catch (error) {
            logError("startwizard.generateWizardSite", error, 1, wizardData);
        }
    };

    const setWizardDataWithSessionStorage = (data: IWizardData) => {
        setWizardData(data);
        try {
            sessionStorage.setItem("wizardData", JSON.stringify(data));
        } catch (e) {
            // Do nothing.
        }
    };
    return (
        <>
            <Switch>
                <Route exact path="/kom-igang">
                    <Header />
                    <WizardSelectDesign wizardData={wizardData} setWizardData={setWizardDataWithSessionStorage} />
                    <Footer />
                </Route>
                <Route exact path="/kom-igang/information">
                    <Header />
                    <WizardBasicInfo wizardData={wizardData} setWizardData={setWizardDataWithSessionStorage} />
                    <Footer />
                </Route>
                <Route exact path="/kom-igang/sprak">
                    <Header />
                    <WizardLanguages wizardData={wizardData} setWizardData={setWizardDataWithSessionStorage} />
                    <Footer />
                </Route>
                <Route exact path="/kom-igang/innehall">
                    <Header />
                    <WizardContent wizardData={wizardData} setWizardData={setWizardDataWithSessionStorage} />
                    <Footer />
                </Route>
                <Route path="/kom-igang/redigera">
                    <GeneratorEdit
                        wizardSite={getWizardSite()}
                        quickStart={true}
                        host={getBrandedQuickstartHost()}
                        wizardData={wizardData}
                    />
                </Route>
            </Switch>
        </>
    );
}
