import styles from "./WizardTopText.module.scss";

interface IProps {
    title: string;
    step: number;
    children: string | React.ReactNode;
}

export function WizardTopText(props: IProps) {
    return (
        <div className={styles.wrapper}>
            <h1>{props.title}</h1>
            <div className={styles.stepCounter}>Steg {props.step} av 4</div>
            <div className={styles.intro}>{props.children}</div>
        </div>
    );
}
