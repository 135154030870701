export const CONTENT_EXAMPLES_DRESS_CODES = {
    WHITE_TIE: {
        title: "Frack",
        description: "Frack för män, lång aftonklänning för kvinnor.",
        id: "WHITE_TIE",
        content: `<p>Högtidligt och stilfullt! Herrar bär frack med vit skjorta, väst och fluga, medan damer bär lång aftonklänning.</p>`,
    },
    BLACK_TIE: {
        title: "Smoking",
        description: "Smoking för män, lång eller kort festklänning för kvinnor.",
        id: "BLACK_TIE",
        content: `<p>Elegant och festlig klädsel! Herrar bär smoking med vit skjorta och fluga, medan damer kan välja en långklänning eller en sofistikerad cocktailklänning.</p>`,
    },
    FORMAL: {
        title: "Mörk kostym",
        description: "Mörk kostym med slips/fluga för män, knälång eller lång klänning för kvinnor.",
        id: "FORMAL",
        content: `<p>För herrar innebär det en mörk kostym med skjorta och slips eller fluga. För damer passar en elegant klänning bäst.</p>`,
    },
    COCKTAIL: {
        title: "Kavaj",
        description: "Kostym (ej nödvändigtvis mörk) för män, elegant klänning/kjol för kvinnor.",
        id: "COCKTAIL",
        content: `<p>För herrar innebär kavaj att man ska bära kostym. Men val av färg både på kavajen och skjortan gör du själv.</p>
        <p></p><p>För damer innebär det klänning, kjol, byxdress eller dräkt.</p>`,
    },
    CASUAL: {
        title: "Sommarfin",
        description: "Mer informellt, ofta ljusa färger och ledigare material.",
        id: "CASUAL",
        content: `<p>Vi önskar oss en härlig, somrig och festlig klädsel! Klänning, kjol, byxdress eller en lättare kostym - gärna i ljusa färger och bekvämt för en dag av kärlek och glädje. </p>`,
    },
    OTHER: {
        title: "Annat",
        description: "",
        id: "OTHER",
    },
    NOT_DECIDED: {
        title: "Inte bestämt än",
        description: "",
        id: "NOT_DECIDED",
    },
} as const;

export type TContentExampleDressCode = keyof typeof CONTENT_EXAMPLES_DRESS_CODES;

export const CONTENT_EXAMPLES_CHILDREN = {
    NO_CHILDREN: {
        title: "Helt barnfritt",
        id: "NO_CHILDREN",
        content:
            "<p>Barn är underbara men den här dagen väljer vi att fira i vuxet sällskap. Spädbarn är självklart välkomna.</p>",
    },
    CHILDREN_ALLOWED_ON_CEREMONY: {
        title: "Barn på vigseln men inte festen",
        id: "CHILDREN_ALLOWED_ON_CEREMONY",
        content:
            "<p>Barnen är välkomna på vår vigsel men under middag och fest väljer vi att fira i vuxet sällskap. Spädbarn får självklart vara med hela dagen.</p>",
    },
    CHILDREN_WELCOME: {
        title: "Barn välkomna på bröllopet",
        id: "CHILDREN_WELCOME",
        content: "<p>Barn är välkomna både på vigseln och på festen efteråt.</p>",
    },
    OTHER: {
        title: "Annat",
        id: "OTHER",
        content:
            "<p>Barn är underbara men den här dagen väljer vi att fira i vuxet sällskap. Spädbarn är självklart välkomna.</p>",
    },
    NOT_DECIDED: {
        title: "Inte bestämt än",
        id: "NOT_DECIDED",
        content:
            "<p>Barn är underbara men den här dagen väljer vi att fira i vuxet sällskap. Spädbarn är självklart välkomna.</p>",
    },
} as const;

export type TContentExampleChildren = keyof typeof CONTENT_EXAMPLES_CHILDREN;

export const CONTENT_EXAMPLES_GIFTS = {
    MONEY: {
        title: "Be om pengar",
        description: "Be om bidrag till tex. bröllopsresan istället för presenter.",
        id: "MONEY",
        content: "<p>Din närvaro är allt vi önskar. Men vill ni ändå uppmärksamma vår dag med en gåva uppskattar vi ett bidrag till vår bröllopsresa.</p><p></p><p>Swisha i sådana fall din gåva till XXX, telefonnummer XXX.</p>",
    },
    GIFTS: {
        title: "Bröllopspresenter",
        description: "Vem kan säga nej till presenter? Låt gästerna veta vad ni önskar er med vår önskelista",
        id: "GIFTS",
        content: "<p>Din närvaro är allt vi önskar. Men vill ni ändå uppmärksamma vår dag med en gåva har vi en önskelista. Om du planerar att ge oss något, reservera det nedan så köper inte du och någon annan gäst samma sak.</p>"
    },
    GUEST_FEE: {
        title: "Kuvertavgift",
        description:
            "Ta ut en kuvertavgift för att täcka kostnaden för gästernas mat och dryck istället för presenter.",
        id: "GUEST_FEE",
        content: "<p>Vi vill inte ha några bröllopsgåvor. Vi vill istället att så många som möjligt av er, våra nära och kära, ska kunna fira med oss. Därför tar vi istället en kuvertavgift på XXX kr per person för att täcka kostnaden för mat och dryck.</p><p></p><p>Swisha din kuvertavgift till XXX.<p>",
    },
    NO_GIFTS: {
        title: "Undanbe gåvor/presenter",
        description: "Vi vill inte ha presenter eller pengar, bara våra gäster sällskap.",
        id: "NO_GIFTS",
        content: "<p>Din närvaro är allt vi önskar. Vi önskar oss inga presenter eller pengar, bara att du firar med oss.</p>",
    },
    OTHER: {
        title: "Annat",
        description: "",
        id: "OTHER",
    },
    NOT_DECIDED: {
        title: "Inte bestämt än",
        description: "",
        id: "NOT_DECIDED",
    },
} as const;

export type TContentExampleGifts = keyof typeof CONTENT_EXAMPLES_GIFTS;
