import dayjs from "dayjs";
import { ISiteV2 } from "../../../interfaces/Site";
import { DesignTemplates } from "../../../modules/EditDesignTemplate/utils/designTemplates";
import { IWizardData } from "../StartWizard";
import { CONTENT_EXAMPLES_CHILDREN, CONTENT_EXAMPLES_DRESS_CODES, CONTENT_EXAMPLES_GIFTS } from "./contentExamples";
import { ISiteModuleGiftRegistry, ISiteModuleText } from "../../../interfaces/ISiteModule";

const removePropertiesUndefinedOrEmptyString = (obj: IWizardData) => {
    Object.keys(obj).forEach((key) => {
        const k = key as keyof IWizardData;
        if (obj[k] === undefined || obj[k] === "") {
            delete obj[k];
        }
    });
    return obj;
};

export const generateWizardSite = (wizardData: IWizardData) => {
    const defaults: Required<IWizardData> = {
        dateStart: dayjs().add(6, "M").format("YYYY-MM-DDTHH:mm"),
        location: "Jönköping",
        name1: "Anton",
        name2: "Emma",
        designTemplate: DesignTemplates.STANDARD,
        dressCode: CONTENT_EXAMPLES_DRESS_CODES.COCKTAIL.id,
        children: CONTENT_EXAMPLES_CHILDREN.NO_CHILDREN.id,
        gifts: CONTENT_EXAMPLES_GIFTS.GIFTS.id,
        dateNotDecided: false,
        languages: [
            {
                languageCode: "sv",
            },
        ],
    };

    wizardData = removePropertiesUndefinedOrEmptyString(wizardData);

    const options: Required<IWizardData> = { ...defaults, ...wizardData };

    let dressCode = CONTENT_EXAMPLES_DRESS_CODES[options.dressCode];
    if (dressCode.id === "OTHER" || dressCode.id === "NOT_DECIDED") {
        dressCode = CONTENT_EXAMPLES_DRESS_CODES.COCKTAIL;
    }

    let gifts = CONTENT_EXAMPLES_GIFTS[options.gifts];
    if (gifts.id === "OTHER" || gifts.id === "NOT_DECIDED") {
        gifts = CONTENT_EXAMPLES_GIFTS.GIFTS;
    }

    const giftsTextModule: ISiteModuleText =  {
        siteModuleType: "Text",
        id: "CLIENTID341f7798-ba24-223d-aa80-58d4b2184c7f",
        hide: false,
        hideInMenu: false,
        menuTitle: {},
        title: {
            contentDataType: "Title",
            text: {
                sv: "Gåvor",
            },
        },
        text: {
            contentDataType: "RichText",
            richText: {
                sv: gifts.content,
            },
        }
    };

    const giftsModuleRegistry: ISiteModuleGiftRegistry = {
        siteModuleType: "GiftRegistry",
        id: "CLIENTID341f7798-ba24-223d-aa80-58d4b2184c7f",
        hide: false,
        hideInMenu: false,
        menuTitle: {},
        title: {
            contentDataType: "Title",
            text: {
                sv: "Gåvor",
            },
        },
        text: {
            contentDataType: "RichText",
            richText: {
                sv: gifts.content,
            },
        },
        giftRegistry: [
            {
                id: "CLIENTID-GIFT-REGISTRY-ITEM78c378d1-d867-48e6-a85f-8f3d98137607",
                title: {
                    contentDataType: "Title",
                    text: {
                        sv: "Champagneglas",
                    },
                },
                description: {
                    contentDataType: "Text",
                    text: {
                        sv: "Vi saknar några riktigt fina bubbelglas. Gärna i modern stil för att passa med resten av våra glas.",
                    },
                },
                reservable: true,
                quantity: 8,
                link: null,
                image: {
                    contentDataType: "Image",
                    image: {
                        path: "uploads/67a07f56e741b5f7122a2623/images/2025-02-03T13:29:15.236Z_ts03ev/",
                        originalName: "champagneglas.jpg",
                        fileName: "2025-02-03T13:29:15.236Z_ts03ev.jpg",
                        temporary: false,
                        isDemoImage: true,
                        sizes: [
                            {
                                size: 1600,
                                fileName: "2025-02-03T13:29:15.236Z_ts03ev_1600.jpg",
                            },
                            {
                                size: 1200,
                                fileName: "2025-02-03T13:29:15.236Z_ts03ev_1200.jpg",
                            },
                            {
                                size: 800,
                                fileName: "2025-02-03T13:29:15.236Z_ts03ev_800.jpg",
                            },
                            {
                                size: 600,
                                fileName: "2025-02-03T13:29:15.236Z_ts03ev_600.jpg",
                            },
                        ],
                        width: 1951,
                        height: 1301,
                    },
                },
            },
            {
                id: "CLIENTID-GIFT-REGISTRY-ITEMd6762e12-490c-6745-e3af-14c0d1ed8b91",
                title: {
                    contentDataType: "Title",
                    text: {
                        sv: "Gryta 12 liter",
                    },
                },
                description: {
                    contentDataType: "Text",
                    text: {},
                },
                reservable: true,
                quantity: 1,
                link: "https://www.cervera.se/produkt/anders-petter-backaryd-gryta-med-glaslock-12-l",
                image: {
                    contentDataType: "Image",
                    image: {
                        path: "uploads/67a07f56e741b5f7122a2623/images/2025-02-03T13:29:24.369Z_mxntrm/",
                        originalName: "gryta.jpg",
                        fileName: "2025-02-03T13:29:24.369Z_mxntrm.jpg",
                        temporary: false,
                        isDemoImage: true,
                        sizes: [
                            {
                                size: 1600,
                                fileName: "2025-02-03T13:29:24.369Z_mxntrm_1600.jpg",
                            },
                            {
                                size: 1200,
                                fileName: "2025-02-03T13:29:24.369Z_mxntrm_1200.jpg",
                            },
                            {
                                size: 800,
                                fileName: "2025-02-03T13:29:24.369Z_mxntrm_800.jpg",
                            },
                            {
                                size: 600,
                                fileName: "2025-02-03T13:29:24.369Z_mxntrm_600.jpg",
                            },
                        ],
                        width: 5472,
                        height: 3648,
                    },
                },
            },
            {
                id: "CLIENTID-GIFT-REGISTRY-ITEM05e31cfb-2a6e-4ff4-cd18-021c08cede62",
                title: {
                    contentDataType: "Title",
                    text: {
                        sv: "Aktivitet",
                    },
                },
                description: {
                    contentDataType: "Text",
                    text: {
                        sv: "Vi hänger gärna med på en rolig aktivitet tillsammans med er!",
                    },
                },
                reservable: true,
                quantity: 1,
                link: null,
                image: {
                    contentDataType: "Image",
                    image: {
                        path: "uploads/67a07f56e741b5f7122a2623/images/2025-02-03T13:29:34.527Z_ey3myd/",
                        originalName: "fallskarm.jpg",
                        fileName: "2025-02-03T13:29:34.527Z_ey3myd.jpg",
                        temporary: false,
                        isDemoImage: true,
                        sizes: [
                            {
                                size: 1600,
                                fileName: "2025-02-03T13:29:34.527Z_ey3myd_1600.jpg",
                            },
                            {
                                size: 1200,
                                fileName: "2025-02-03T13:29:34.527Z_ey3myd_1200.jpg",
                            },
                            {
                                size: 800,
                                fileName: "2025-02-03T13:29:34.527Z_ey3myd_800.jpg",
                            },
                            {
                                size: 600,
                                fileName: "2025-02-03T13:29:34.527Z_ey3myd_600.jpg",
                            },
                        ],
                        width: 1582,
                        height: 1160,
                    },
                },
            },
            {
                id: "CLIENTID-GIFT-REGISTRY-ITEMcaf4d13a-49c8-2348-b59f-d7f515d1b6bd",
                title: {
                    contentDataType: "Title",
                    text: {
                        sv: "Äppleträd Aroma",
                    },
                },
                description: {
                    contentDataType: "Text",
                    text: {},
                },
                reservable: true,
                quantity: 1,
                link: "https://www.blomsterlandet.se/produkter/vaxter/utomhus/frukttrad/appletrad/appletrad-aroma-27643/",
                image: {
                    contentDataType: "Image",
                    image: {
                        path: "uploads/67a07f56e741b5f7122a2623/images/2025-02-03T13:29:40.303Z_kxhvke/",
                        originalName: "appletrad.jpg",
                        fileName: "2025-02-03T13:29:40.303Z_kxhvke.jpg",
                        temporary: false,
                        isDemoImage: true,
                        sizes: [
                            {
                                size: 1600,
                                fileName: "2025-02-03T13:29:40.303Z_kxhvke_1600.jpg",
                            },
                            {
                                size: 1200,
                                fileName: "2025-02-03T13:29:40.303Z_kxhvke_1200.jpg",
                            },
                            {
                                size: 800,
                                fileName: "2025-02-03T13:29:40.303Z_kxhvke_800.jpg",
                            },
                            {
                                size: 600,
                                fileName: "2025-02-03T13:29:40.303Z_kxhvke_600.jpg",
                            },
                        ],
                        width: 1950,
                        height: 1300,
                    },
                },
            },
            {
                id: "CLIENTID-GIFT-REGISTRY-ITEMdde849fb-ede9-5cb6-0b5d-abd208fb578c",
                title: {
                    contentDataType: "Title",
                    text: {
                        sv: "Motionslopp",
                    },
                },
                description: {
                    contentDataType: "Text",
                    text: {
                        sv: "Vi gör gärna någon form av motionslopp tillsammans med er. Löpning eller längdskidor är lika roligt båda två. Glöm inte att reservera, ett lopp nästa år räcker gott.",
                    },
                },
                reservable: true,
                quantity: 1,
                link: null,
                image: {
                    contentDataType: "Image",
                    image: {
                        path: "uploads/67a07f56e741b5f7122a2623/images/2025-02-03T13:29:47.478Z_f7606d/",
                        originalName: "motionslopp.jpg",
                        fileName: "2025-02-03T13:29:47.478Z_f7606d.jpg",
                        temporary: false,
                        isDemoImage: true,
                        sizes: [
                            {
                                size: 1600,
                                fileName: "2025-02-03T13:29:47.478Z_f7606d_1600.jpg",
                            },
                            {
                                size: 1200,
                                fileName: "2025-02-03T13:29:47.478Z_f7606d_1200.jpg",
                            },
                            {
                                size: 800,
                                fileName: "2025-02-03T13:29:47.478Z_f7606d_800.jpg",
                            },
                            {
                                size: 600,
                                fileName: "2025-02-03T13:29:47.478Z_f7606d_600.jpg",
                            },
                        ],
                        width: 1200,
                        height: 800,
                    },
                },
            },
            {
                id: "CLIENTID-GIFT-REGISTRY-ITEM4b78a831-2aab-f3c8-a8c9-64ab5784d4bb",
                title: {
                    contentDataType: "Title",
                    text: {
                        sv: "Bidrag till vår bröllopsresa",
                    },
                },
                description: {
                    contentDataType: "Text",
                    text: {
                        sv: "Vi ska till Italien på bröllopsresa och hoppas göra flera roliga saker där. Vill ni bidra till vår resa? Swisha vår toastmaster Stefan, 070-123 45 67.",
                    },
                },
                reservable: false,
                quantity: 1,
                link: null,
                image: {
                    contentDataType: "Image",
                    image: {
                        path: "uploads/67a07f56e741b5f7122a2623/images/2025-02-03T13:29:55.284Z_mi8p13/",
                        originalName: "flygplan.jpg",
                        fileName: "2025-02-03T13:29:55.284Z_mi8p13.jpg",
                        temporary: false,
                        isDemoImage: true,
                        sizes: [
                            {
                                size: 1600,
                                fileName: "2025-02-03T13:29:55.284Z_mi8p13_1600.jpg",
                            },
                            {
                                size: 1200,
                                fileName: "2025-02-03T13:29:55.284Z_mi8p13_1200.jpg",
                            },
                            {
                                size: 800,
                                fileName: "2025-02-03T13:29:55.284Z_mi8p13_800.jpg",
                            },
                            {
                                size: 600,
                                fileName: "2025-02-03T13:29:55.284Z_mi8p13_600.jpg",
                            },
                        ],
                        width: 1950,
                        height: 1300,
                    },
                },
            },
        ],
        showGiftRegistryButton: {
            contentDataType: "Text",
            text: {
                sv: "Visa önskelista",
                en: "SHOW GIFT REGISTRY",
            },
        },
        reserveButton: {
            contentDataType: "Text",
            text: {
                sv: "Reservera",
                en: "RESERVE",
            },
        },
        cancelReservationButton: {
            contentDataType: "Text",
            text: {
                sv: "Ångra reservation",
                en: "CANCEL RESERVATION",
            },
        },
        labelReserveQuantity: {
            contentDataType: "Text",
            text: {
                sv: "Antal",
                en: "Quantity",
            },
        },
        labelReservePassword: {
            contentDataType: "Text",
            text: {
                sv: "Lösenord (för att kunna ångra senare)",
                en: "Password (if you want to cancel the reservation later)",
            },
        },
        labelCancelReservationQuantity: {
            contentDataType: "Text",
            text: {
                sv: "Antal du hade reserverat",
                en: "Quantity reserved",
            },
        },
        labelCancelReservationPassword: {
            contentDataType: "Text",
            text: {
                sv: "Lösenord",
                en: "Password",
            },
        },
        statusTextReservedSingular: {
            contentDataType: "Text",
            text: {
                sv: "Reserverad",
                en: "Reserved",
            },
        },
        statusTextQuantity: {
            contentDataType: "Text",
            text: {
                sv: "Antal",
                en: "Quantity",
            },
        },
        statusTextReservedPlural: {
            contentDataType: "Text",
            text: {
                sv: "reserverade",
                en: "reserved",
            },
        },
    };

    const site: ISiteV2 = {
        theme: options.designTemplate.theme,
        fonts: {
            heading: options.designTemplate.fonts.heading,
            text: options.designTemplate.fonts.text,
        },
        siteGraphic: options.designTemplate.siteGraphic,
        colorScheme: options.designTemplate.colorScheme,
        mostRecentSelectedDesignTemplate: wizardData.designTemplate?.name,
        customDomainActive: false,
        customDomainActiveDate: null,
        customDomainRegistrationsAvailable: 0,
        metaNoindex: false,
        active: false,
        password: null,
        hasRsvps: false,
        quickStart: true,
        siteVersion: "",
        _id: "START-WIZARD",
        giftRegistryReservations: [],
        availableLanguages: options.languages,
        activeDate: null,
        host: "demo.brollopssida.se",
        usesSiteModules: true,
        siteModules: [
            {
                siteModuleType: "Header",
                id: "CLIENTIDb1deea12-4b9e-f3f1-a6de-24780eafc4cf",
                hide: false,
                hideInMenu: false,
                menuTitle: {
                    sv: "Start",
                    en: "Start",
                },
                title: {
                    contentDataType: "Title",
                    text: {
                        sv: options.name1 + " & " + options.name2,
                    },
                },
                secondLine: {
                    contentDataType: "Text",
                    text: {
                        sv: options.location + " - " + dayjs(options.dateStart).format("D/M YYYY"),
                    },
                },
                image: {
                    contentDataType: "Image",
                    image: {
                        path: "uploads/67a07f56e741b5f7122a2623/images/2025-02-03T13:25:45.652Z_mgforo/",
                        originalName: "2024-11-05T11_30_46.480Z_2elzxz.jpg",
                        fileName: "2025-02-03T13:25:45.652Z_mgforo.jpg",
                        temporary: false,
                        isDemoImage: true,
                        sizes: [
                            {
                                size: 1600,
                                fileName: "2025-02-03T13:25:45.652Z_mgforo_1600.jpg",
                            },
                            {
                                size: 1200,
                                fileName: "2025-02-03T13:25:45.652Z_mgforo_1200.jpg",
                            },
                            {
                                size: 800,
                                fileName: "2025-02-03T13:25:45.652Z_mgforo_800.jpg",
                            },
                            {
                                size: 600,
                                fileName: "2025-02-03T13:25:45.652Z_mgforo_600.jpg",
                            },
                        ],
                        width: 1350,
                        height: 900,
                    },
                    userSelectedImageSize: "MEDIUM",
                },
            },
            {
                siteModuleType: "Countdown",
                id: "CLIENTID5def4945-b0b3-26db-bb32-d86a8df3535e",
                hide: false,
                hideInMenu: true,
                menuTitle: {},
                countdownDate: dayjs(options.dateStart).format("YYYY-MM-DDTHH:mm"),
            },
            {
                siteModuleType: "Location",
                id: "CLIENTID5654ce81-3fdc-8f75-79d3-c41e62ad44b7",
                hide: false,
                hideInMenu: false,
                menuTitle: {},
                title: {
                    contentDataType: "Title",
                    text: {
                        sv: "Vigsel",
                    },
                },
                map: {
                    contentDataType: "Map",
                    mapTheme: "NORMAL",
                    markerColor: null,
                    zoom: null,
                    map: {
                        size: 640,
                        path: "maps/67a07f56e741b5f7122a2623/640x2_8429fd27110775f04b414d8551c326df43b6ad9065a2f75ae2975e9c178b5e07.png",
                        location: "Nykyrka Kyrka, Mullsjö",
                        sizes: [
                            {
                                size: 640,
                                path: "maps/67a07f56e741b5f7122a2623/640x2_8429fd27110775f04b414d8551c326df43b6ad9065a2f75ae2975e9c178b5e07.png",
                            },
                            {
                                size: 420,
                                path: "maps/67a07f56e741b5f7122a2623/420x2_5f40eb6de0b79a40e1a274e87dafbbe7b509edd517fe533d6c3bfa30a3668c58.png",
                            },
                        ],
                        temporary: false,
                        isDemoImage: true,
                    },
                },
                secondTitle: {
                    contentDataType: "Title",
                    text: {
                        sv: dayjs(options.dateStart).format("HH:mm") + " - Vigsel i " + options.location,
                    },
                },
                text: {
                    contentDataType: "RichText",
                    richText: {
                        sv: "<p>Klockan tre börjar vår vigsel i Nykyrka kyrka i Mullsjö. Du hittar smidigast dit genom att följa väg 94 från Åmål ut mot Hägersta, sväng vänster strax innan den stora rondellen där det står skyltat kyrka.</p><p></p><p>Adress: Nykyrkekvarnbacken 2, 123 45, Mullsjö</p>",
                    },
                },
                image: {
                    contentDataType: "Image",
                    image: {
                        path: "uploads/67a07f56e741b5f7122a2623/images/2025-02-03T13:26:13.699Z_z8ctm9/",
                        originalName: "2024-11-05T11_54_48.048Z_9u96xw.jpg",
                        fileName: "2025-02-03T13:26:13.699Z_z8ctm9.jpg",
                        temporary: false,
                        isDemoImage: true,
                        sizes: [
                            {
                                size: 1600,
                                fileName: "2025-02-03T13:26:13.699Z_z8ctm9_1600.jpg",
                            },
                            {
                                size: 1200,
                                fileName: "2025-02-03T13:26:13.699Z_z8ctm9_1200.jpg",
                            },
                            {
                                size: 800,
                                fileName: "2025-02-03T13:26:13.699Z_z8ctm9_800.jpg",
                            },
                            {
                                size: 600,
                                fileName: "2025-02-03T13:26:13.699Z_z8ctm9_600.jpg",
                            },
                        ],
                        width: 2000,
                        height: 1333,
                    },
                },
            },
            {
                siteModuleType: "Location",
                id: "CLIENTIDfd573686-adbc-f0bf-b267-0781a1ef2763",
                hide: false,
                hideInMenu: false,
                menuTitle: {
                    sv: "Festen",
                },
                title: {
                    contentDataType: "Title",
                    text: {
                        sv: "Middag och fest",
                    },
                },
                map: {
                    contentDataType: "Map",
                    mapTheme: "NORMAL",
                    markerColor: null,
                    zoom: null,
                    map: {
                        size: 640,
                        path: "maps/67a07f56e741b5f7122a2623/640x2_bca9e3e4212bcfc3f70fc4528c1ce33cbb4cb3ca9dfd0c776fc83c2ab6ea6c10.png",
                        location: "Smedeby",
                        sizes: [
                            {
                                size: 640,
                                path: "maps/67a07f56e741b5f7122a2623/640x2_bca9e3e4212bcfc3f70fc4528c1ce33cbb4cb3ca9dfd0c776fc83c2ab6ea6c10.png",
                            },
                            {
                                size: 420,
                                path: "maps/67a07f56e741b5f7122a2623/420x2_bd1110af922197cf64c371d2d2602dbcf8afd868464b9fccf99ab37254c66832.png",
                            },
                        ],
                        temporary: false,
                        isDemoImage: true,
                    },
                },
                secondTitle: {
                    contentDataType: "Title",
                    text: {
                        sv: "Middag och fest i Smedeby",
                    },
                },
                text: {
                    contentDataType: "RichText",
                    richText: {
                        sv: "<p>Runt 16 planeras bröllopsparet anlända till Smedeby. Väl där är det middag med påföljande fest. </p><p></p><p>Från kyrkan till Smedeby är det ungefär en kilometer. Ta gärna följe med någon som hittar i trakten, annars hittar man rätt med adressen nedan.</p><p></p><p>Adress: Smedeby 1, 123 45, Gröndahl</p>",
                    },
                },
                image: {
                    contentDataType: "Image",
                    image: {
                        path: "uploads/67a07f56e741b5f7122a2623/images/2025-02-03T13:26:24.445Z_bm4gpy/",
                        originalName: "smedeby.jpg",
                        fileName: "2025-02-03T13:26:24.445Z_bm4gpy.jpg",
                        temporary: false,
                        isDemoImage: true,
                        sizes: [
                            {
                                size: 1600,
                                fileName: "2025-02-03T13:26:24.445Z_bm4gpy_1600.jpg",
                            },
                            {
                                size: 1200,
                                fileName: "2025-02-03T13:26:24.445Z_bm4gpy_1200.jpg",
                            },
                            {
                                size: 800,
                                fileName: "2025-02-03T13:26:24.445Z_bm4gpy_800.jpg",
                            },
                            {
                                size: 600,
                                fileName: "2025-02-03T13:26:24.445Z_bm4gpy_600.jpg",
                            },
                        ],
                        width: 1200,
                        height: 800,
                    },
                },
            },
            {
                siteModuleType: "TextAndImageList",
                id: "CLIENTIDc09c6f1a-f873-494f-928f-6ae6ebd1a273",
                hide: false,
                hideInMenu: false,
                menuTitle: {},
                title: {
                    contentDataType: "Title",
                    text: {
                        sv: "Bra att veta",
                    },
                },
                list: [
                    {
                        id: "CLIENTID-LIST-ITEM673452d7-e5cf-6693-84b3-47703473503f",
                        title: {
                            contentDataType: "Title",
                            text: {
                                sv: "Mat och dryck",
                            },
                        },
                        text: {
                            contentDataType: "RichText",
                            richText: {
                                sv: "<p>Förrätt, huvudrätt och traditionsenlig bröllopstårta. Vi bjuder på dryck såväl under middagen som i baren efteråt.</p>",
                            },
                        },
                        image: {
                            contentDataType: "Image",
                            image: {
                                path: "uploads/67a07f56e741b5f7122a2623/images/2025-02-03T13:26:41.904Z_yd5ojv/",
                                originalName: "mat.jpg",
                                fileName: "2025-02-03T13:26:41.904Z_yd5ojv.jpg",
                                temporary: false,
                                isDemoImage: true,
                                sizes: [
                                    {
                                        size: 1600,
                                        fileName: "2025-02-03T13:26:41.904Z_yd5ojv_1600.jpg",
                                    },
                                    {
                                        size: 1200,
                                        fileName: "2025-02-03T13:26:41.904Z_yd5ojv_1200.jpg",
                                    },
                                    {
                                        size: 800,
                                        fileName: "2025-02-03T13:26:41.904Z_yd5ojv_800.jpg",
                                    },
                                    {
                                        size: 600,
                                        fileName: "2025-02-03T13:26:41.904Z_yd5ojv_600.jpg",
                                    },
                                ],
                                width: 2000,
                                height: 1333,
                            },
                        },
                    },
                    {
                        id: "CLIENTID-LIST-ITEM35fc33c7-ec17-4243-097f-c585254bfe4c",
                        title: {
                            contentDataType: "Title",
                            text: {
                                sv: "Boende",
                            },
                        },
                        text: {
                            contentDataType: "RichText",
                            richText: {
                                sv: '<p>Om du kommer långväga och vill övernatta rekommenderar vi <a href="https://www.google.se" rel="noreferrer" target="_blank">Hotell Rubin</a> som ligger nära festlokalen.</p>',
                            },
                        },
                        image: {
                            contentDataType: "Image",
                            image: {
                                path: "uploads/67a07f56e741b5f7122a2623/images/2025-02-03T13:26:46.958Z_rmiixm/",
                                originalName: "boende.jpg",
                                fileName: "2025-02-03T13:26:46.958Z_rmiixm.jpg",
                                temporary: false,
                                isDemoImage: true,
                                sizes: [
                                    {
                                        size: 1600,
                                        fileName: "2025-02-03T13:26:46.958Z_rmiixm_1600.jpg",
                                    },
                                    {
                                        size: 1200,
                                        fileName: "2025-02-03T13:26:46.958Z_rmiixm_1200.jpg",
                                    },
                                    {
                                        size: 800,
                                        fileName: "2025-02-03T13:26:46.958Z_rmiixm_800.jpg",
                                    },
                                    {
                                        size: 600,
                                        fileName: "2025-02-03T13:26:46.958Z_rmiixm_600.jpg",
                                    },
                                ],
                                width: 1951,
                                height: 1299,
                            },
                        },
                    },
                    {
                        id: "CLIENTID-LIST-ITEM70856bae-325d-6ac0-9301-ac920e834f04",
                        title: {
                            contentDataType: "Title",
                            text: {
                                sv: "Klädsel: " + dressCode.title,
                            },
                        },
                        text: {
                            contentDataType: "RichText",
                            richText: {
                                sv: dressCode.content,
                            },
                        },
                        image: {
                            contentDataType: "Image",
                            image: {
                                path: "uploads/67a07f56e741b5f7122a2623/images/2025-02-03T13:26:54.436Z_rhpgzi/",
                                originalName: "kladsel.jpg",
                                fileName: "2025-02-03T13:26:54.436Z_rhpgzi.jpg",
                                temporary: false,
                                isDemoImage: true,
                                sizes: [
                                    {
                                        size: 1600,
                                        fileName: "2025-02-03T13:26:54.436Z_rhpgzi_1600.jpg",
                                    },
                                    {
                                        size: 1200,
                                        fileName: "2025-02-03T13:26:54.436Z_rhpgzi_1200.jpg",
                                    },
                                    {
                                        size: 800,
                                        fileName: "2025-02-03T13:26:54.436Z_rhpgzi_800.jpg",
                                    },
                                    {
                                        size: 600,
                                        fileName: "2025-02-03T13:26:54.436Z_rhpgzi_600.jpg",
                                    },
                                ],
                                width: 2000,
                                height: 1333,
                            },
                        },
                    },
                    {
                        id: "CLIENTID-LIST-ITEM9ef7a6b9-a090-eb9d-a9d7-181340f69345",
                        title: {
                            contentDataType: "Title",
                            text: {
                                sv: "Barn",
                            },
                        },
                        text: {
                            contentDataType: "RichText",
                            richText: {
                                sv: CONTENT_EXAMPLES_CHILDREN[options.children].content,
                            },
                        },
                        image: {
                            contentDataType: "Image",
                            image: {
                                path: "uploads/67a07f56e741b5f7122a2623/images/2025-02-03T13:27:01.610Z_2pn6js/",
                                originalName: "barn.jpg",
                                fileName: "2025-02-03T13:27:01.610Z_2pn6js.jpg",
                                temporary: false,
                                isDemoImage: true,
                                sizes: [
                                    {
                                        size: 1600,
                                        fileName: "2025-02-03T13:27:01.610Z_2pn6js_1600.jpg",
                                    },
                                    {
                                        size: 1200,
                                        fileName: "2025-02-03T13:27:01.610Z_2pn6js_1200.jpg",
                                    },
                                    {
                                        size: 800,
                                        fileName: "2025-02-03T13:27:01.610Z_2pn6js_800.jpg",
                                    },
                                    {
                                        size: 600,
                                        fileName: "2025-02-03T13:27:01.610Z_2pn6js_600.jpg",
                                    },
                                ],
                                width: 1200,
                                height: 800,
                            },
                        },
                    },
                ],
            },
            {
                siteModuleType: "PeopleList",
                id: "CLIENTID843057e5-51ac-1374-ea6a-9ec7d9c84967",
                hide: false,
                hideInMenu: false,
                menuTitle: {},
                title: {
                    contentDataType: "Title",
                    text: {
                        sv: "Brudfölje",
                    },
                },
                list: [
                    {
                        id: "CLIENTID-LIST-ITEMa905c546-2d33-d115-eb95-4eb8ac9e6e7d",
                        name: {
                            contentDataType: "Title",
                            text: {
                                sv: "Jonas",
                            },
                        },
                        title: {
                            contentDataType: "Text",
                            text: {
                                sv: "Bestman",
                            },
                        },
                        text: {
                            contentDataType: "RichText",
                            richText: {},
                        },
                        image: {
                            contentDataType: "Image",
                            image: {
                                path: "uploads/67a07f56e741b5f7122a2623/images/2025-02-03T13:27:10.236Z_rkmlq8/",
                                originalName: "jonas.jpg",
                                fileName: "2025-02-03T13:27:10.236Z_rkmlq8.jpg",
                                temporary: false,
                                isDemoImage: true,
                                sizes: [
                                    {
                                        size: 1600,
                                        fileName: "2025-02-03T13:27:10.236Z_rkmlq8_1600.jpg",
                                    },
                                    {
                                        size: 1200,
                                        fileName: "2025-02-03T13:27:10.236Z_rkmlq8_1200.jpg",
                                    },
                                    {
                                        size: 800,
                                        fileName: "2025-02-03T13:27:10.236Z_rkmlq8_800.jpg",
                                    },
                                    {
                                        size: 600,
                                        fileName: "2025-02-03T13:27:10.236Z_rkmlq8_600.jpg",
                                    },
                                ],
                                width: 3263,
                                height: 3263,
                            },
                        },
                    },
                    {
                        id: "CLIENTID-LIST-ITEM3114ce96-befc-b07b-0a24-54c8361121da",
                        name: {
                            contentDataType: "Title",
                            text: {
                                sv: "Camilla",
                            },
                        },
                        title: {
                            contentDataType: "Text",
                            text: {
                                sv: "Tärna",
                            },
                        },
                        text: {
                            contentDataType: "RichText",
                            richText: {},
                        },
                        image: {
                            contentDataType: "Image",
                            image: {
                                path: "uploads/67a07f56e741b5f7122a2623/images/2025-02-03T13:27:16.377Z_4k7wob/",
                                originalName: "camilla.jpg",
                                fileName: "2025-02-03T13:27:16.377Z_4k7wob.jpg",
                                temporary: false,
                                isDemoImage: true,
                                sizes: [
                                    {
                                        size: 1600,
                                        fileName: "2025-02-03T13:27:16.377Z_4k7wob_1600.jpg",
                                    },
                                    {
                                        size: 1200,
                                        fileName: "2025-02-03T13:27:16.377Z_4k7wob_1200.jpg",
                                    },
                                    {
                                        size: 800,
                                        fileName: "2025-02-03T13:27:16.377Z_4k7wob_800.jpg",
                                    },
                                    {
                                        size: 600,
                                        fileName: "2025-02-03T13:27:16.377Z_4k7wob_600.jpg",
                                    },
                                ],
                                width: 600,
                                height: 600,
                            },
                        },
                    },
                    {
                        id: "CLIENTID-LIST-ITEM95ba5d83-43a8-f243-0d8f-5377394abe3d",
                        name: {
                            contentDataType: "Title",
                            text: {
                                sv: "Caroline",
                            },
                        },
                        title: {
                            contentDataType: "Text",
                            text: {
                                sv: "Tärna",
                            },
                        },
                        text: {
                            contentDataType: "RichText",
                            richText: {},
                        },
                        image: {
                            contentDataType: "Image",
                            image: {
                                path: "uploads/67a07f56e741b5f7122a2623/images/2025-02-03T13:27:21.746Z_bxnnmx/",
                                originalName: "caroline.jpg",
                                fileName: "2025-02-03T13:27:21.746Z_bxnnmx.jpg",
                                temporary: false,
                                isDemoImage: true,
                                sizes: [
                                    {
                                        size: 1600,
                                        fileName: "2025-02-03T13:27:21.746Z_bxnnmx_1600.jpg",
                                    },
                                    {
                                        size: 1200,
                                        fileName: "2025-02-03T13:27:21.746Z_bxnnmx_1200.jpg",
                                    },
                                    {
                                        size: 800,
                                        fileName: "2025-02-03T13:27:21.746Z_bxnnmx_800.jpg",
                                    },
                                    {
                                        size: 600,
                                        fileName: "2025-02-03T13:27:21.746Z_bxnnmx_600.jpg",
                                    },
                                ],
                                width: 2233,
                                height: 2233,
                            },
                        },
                    },
                ],
            },
            gifts.id === "GIFTS" ? giftsModuleRegistry : giftsTextModule,
            {
                siteModuleType: "Form",
                id: "CLIENTIDd43dc552-ceab-f8cf-69f7-19e9b1135919",
                hide: false,
                hideInMenu: false,
                menuTitle: {},
                title: {
                    contentDataType: "Title",
                    text: {
                        sv: "Tal",
                    },
                },
                sendButton: {
                    contentDataType: "Text",
                    text: {
                        sv: "Skicka",
                        en: "SEND",
                    },
                },
                showFormButton: {
                    contentDataType: "Text",
                    text: {
                        sv: "Visa formulär",
                        en: "SHOW FORM",
                    },
                },
                text: {
                    contentDataType: "RichText",
                    richText: {
                        sv: "<p>Om du vill hålla tal, uppträda eller spexa får du gärna anmäla det nedan. Vi ser inte anmälan utan informationen skickas direkt till våra toastmasters Kalle och Anna. </p><p></p><p>Vi har många härliga vänner och familjemedlemmar som är duktiga talare. Vi vill såklart höra alla så tänk på att hålla er relativt kort. 5 minuter brukar vara lagom.</p><p></p><p>Har du frågor om tal och spex kan du mejla eller ringa våra toastmasters. </p><p></p><p><strong>Kalle Svensson</strong></p><p>070-123 12 12</p><p>kalle@hotmail.se</p><p></p><p><strong>Anna Svensson</strong></p><p>070-123 12 12</p><p>anna@hotmail.se</p>",
                    },
                },
                form: {
                    contentDataType: "Form",
                    formElements: [
                        {
                            id: "form-elementb4ce8c6c-362e-c566-afa8-256ba5966095",
                            label: {
                                sv: "Förnamn",
                            },
                            showConditions: [],
                            required: true,
                            formElementType: "ShortText",
                        },
                        {
                            id: "form-element40d43f46-2644-7d2e-2aae-19eda480866b",
                            label: {
                                sv: "Efternamn",
                            },
                            showConditions: [],
                            required: true,
                            formElementType: "ShortText",
                        },
                        {
                            id: "form-element0f1632d9-eda3-f44f-83b6-a6b9cc7190ed",
                            label: {
                                sv: "Telefonnummer",
                            },
                            showConditions: [],
                            required: false,
                            formElementType: "ShortText",
                        },
                        {
                            id: "form-elementab185209-2767-b2fb-442b-990b52f8e742",
                            label: {
                                sv: "E-postadress",
                            },
                            showConditions: [],
                            required: true,
                            formElementType: "ShortText",
                        },
                        {
                            id: "form-element9bda7131-b266-caa4-32db-d2d5cfa520cf",
                            label: {
                                sv: "Vad ska du göra? (Tex. tal, spex eller lek) ",
                            },
                            showConditions: [],
                            required: false,
                            formElementType: "ShortText",
                        },
                        {
                            id: "form-element2fa32985-2c24-8bfc-f34a-05c3db6827a1",
                            label: {
                                sv: "Behöver du något? (Tex. projektor eller mycket yta)",
                            },
                            showConditions: [],
                            required: false,
                            formElementType: "ShortText",
                        },
                        {
                            id: "form-element1a2a4b74-a9f6-de39-bf54-354c660ef4e2",
                            label: {
                                sv: "Ungefär hur lång tid tar det?",
                            },
                            showConditions: [],
                            required: true,
                            formElementType: "ShortText",
                        },
                        {
                            id: "form-elementc6b43f15-02d9-8177-c2f6-f1c579fa389f",
                            label: {
                                sv: "Din relation till brudparet",
                            },
                            showConditions: [],
                            required: true,
                            formElementType: "ShortText",
                        },
                    ],
                },
                confirmationTitle: {
                    contentDataType: "Title",
                    text: {
                        sv: "Tack!",
                        en: "Thank you!",
                    },
                },
                confirmationText: {
                    contentDataType: "RichText",
                    richText: {
                        sv: "<p>Nu är formuläret skickat.</p>",
                        en: "<p>Form submitted.</p>",
                    },
                },
            },
            {
                siteModuleType: "Rsvp",
                id: "CLIENTID4b4db215-f38c-b4b1-fd2a-70d5ef1924e0",
                hide: false,
                hideInMenu: false,
                menuTitle: {},
                title: {
                    contentDataType: "Title",
                    text: {
                        sv: "OSA",
                    },
                },
                selectNumberOfGuestsText: {
                    contentDataType: "Text",
                    text: {
                        sv: "Välj antalet personer att OSA för:",
                        en: "Select number of guests",
                    },
                },
                sendButton: {
                    contentDataType: "Text",
                    text: {
                        sv: "Skicka",
                        en: "SEND",
                    },
                },
                oneGuestRsvpButton: {
                    contentDataType: "Text",
                    text: {
                        sv: "OSA",
                    },
                },
                text: {
                    contentDataType: "RichText",
                    richText: {
                        sv: "<p>OSA i formuläret nedan senast den 10/4. Om du har frågor hör gärna av dig till vårt värdpar.</p>",
                    },
                },
                maxNumberOfGuests: 5,
                form: {
                    contentDataType: "Form",
                    formElements: [
                        {
                            id: "form-elementfeb08401-31a5-4bfd-fbda-8e9e6f558315",
                            label: {
                                sv: "Förnamn",
                            },
                            showConditions: [],
                            required: true,
                            formElementType: "ShortText",
                        },
                        {
                            id: "form-elementacaa4297-d516-cb27-6a91-662aedf3e615",
                            label: {
                                sv: "Efternamn",
                            },
                            showConditions: [],
                            required: true,
                            formElementType: "ShortText",
                        },
                        {
                            id: "form-element6648ab9f-49f4-1fbf-b78b-8d6841cf0a07",
                            label: {
                                sv: "Telefonnummer",
                            },
                            showConditions: [],
                            required: true,
                            formElementType: "ShortText",
                        },
                        {
                            id: "form-elementa6986c90-937a-c547-35b7-e21ddcd60908",
                            label: {
                                sv: "E-postadress",
                            },
                            showConditions: [],
                            required: true,
                            formElementType: "ShortText",
                        },
                        {
                            id: "form-element5edf5b41-3af6-7b57-1fd3-298a0bc532f5",
                            label: {
                                sv: "Kommer du på bröllopet?",
                            },
                            showConditions: [],
                            formElementType: "SingleSelect",
                            required: true,
                            options: [
                                {
                                    id: "FORM-OPTION-ec796a66-0a54-be0e-ba93-e16dd3bddbce",
                                    text: {
                                        sv: "Ja, självklart!",
                                        en: "",
                                    },
                                },
                                {
                                    id: "FORM-OPTION-88dbdbcb-157f-bdbd-8953-a10ab440970b",
                                    text: {
                                        sv: "Nej, tyvärr",
                                    },
                                },
                            ],
                        },
                        {
                            id: "form-elementee07f313-0ad1-8bc5-cda4-f65ec283bed1",
                            label: {
                                sv: "Önskar du åka buss till och från festlokalen?",
                            },
                            showConditions: [
                                {
                                    type: "SHOW",
                                    formElementId: "form-element5edf5b41-3af6-7b57-1fd3-298a0bc532f5",
                                    formElementOptionIds: ["FORM-OPTION-ec796a66-0a54-be0e-ba93-e16dd3bddbce"],
                                },
                            ],
                            formElementType: "SingleSelect",
                            required: true,
                            options: [
                                {
                                    id: "FORM-OPTION-3691172d-8ab9-90a6-a80f-b8bb893ae2f9",
                                    text: {
                                        sv: "Ja tack",
                                    },
                                },
                                {
                                    id: "FORM-OPTION-0faaf26d-4112-2933-2629-f363548d4210",
                                    text: {
                                        sv: "Nej tack",
                                    },
                                },
                            ],
                        },
                        {
                            id: "form-elementba0e5c1f-ddf3-65e1-0503-08f2dcc2f9d6",
                            label: {
                                sv: "Eventuella allergier eller annan specialkost",
                            },
                            showConditions: [
                                {
                                    type: "SHOW",
                                    formElementId: "form-element5edf5b41-3af6-7b57-1fd3-298a0bc532f5",
                                    formElementOptionIds: ["FORM-OPTION-ec796a66-0a54-be0e-ba93-e16dd3bddbce"],
                                },
                            ],
                            required: false,
                            formElementType: "ShortText",
                        },
                        {
                            id: "form-element6d574233-c920-2f0a-b15a-4bd01e64f66d",
                            label: {
                                sv: "Om denna låten kommer på festen lovar jag att dansa",
                            },
                            showConditions: [
                                {
                                    type: "SHOW",
                                    formElementId: "form-element5edf5b41-3af6-7b57-1fd3-298a0bc532f5",
                                    formElementOptionIds: ["FORM-OPTION-ec796a66-0a54-be0e-ba93-e16dd3bddbce"],
                                },
                            ],
                            required: false,
                            formElementType: "ShortText",
                        },
                    ],
                },
                confirmationTitle: {
                    contentDataType: "Title",
                    text: {
                        sv: "Tack!",
                        en: "Thank you!",
                    },
                },
                confirmationText: {
                    contentDataType: "RichText",
                    richText: {
                        sv: "<p>Tack för att du skickat in OSA, vi har tagit emot dina uppgifter.</p>",
                        en: "<p>We have received you RSVPs. </p>",
                    },
                },
                guestIndexTitle: {
                    contentDataType: "Text",
                    text: {
                        sv: "Person",
                        en: "Guest",
                    },
                },
            },
        ],
        formRecipients: [],
    };

    return site;
};
