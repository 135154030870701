import { LinkButton } from "../../../../components/button/LinkButton";
import { Input } from "../../../../editComponents/form/Input/Input";
import { Switch } from "../../../../editComponents/form/Switch/Switch";
import { IWizardData } from "../../StartWizard";
import { WizardTopText } from "../WizardTopText/WizardTopText";
import styles from "./WizardBasicInfo.module.scss";

interface IProps {
    wizardData: IWizardData;
    setWizardData: (data: IWizardData) => void;
}

export function WizardBasicInfo(props: IProps) {
    const handleChangeDateNotDecided = (value: boolean) => {
        if (value) {
            props.setWizardData({ ...props.wizardData, dateNotDecided: true, dateStart: undefined });
        } else {
            props.setWizardData({ ...props.wizardData, dateNotDecided: false });
        }
    };
    return (
        <div className={styles.wrapper}>
            <WizardTopText title="Några korta frågor" step={2}>
                Kul att ni ska gifta er! Vi har några korta frågor för att ni snabbt ska komma igång med er
                bröllopshemsida.
            </WizardTopText>

            <div className={styles.section}>
                <div>
                    <Input
                        label="Ditt förnamn"
                        value={props.wizardData.name1 || ""}
                        handleChange={(value) => props.setWizardData({ ...props.wizardData, name1: value })}
                        id="name1"
                        name="name1"
                        theme="LIGHT"
                    />
                </div>
                <div>
                    <Input
                        label="Din partners förnamn"
                        value={props.wizardData.name2 || ""}
                        handleChange={(value) => props.setWizardData({ ...props.wizardData, name2: value })}
                        id="name2"
                        name="name2"
                        theme="LIGHT"
                    />
                </div>
                <div>
                    <Input
                        label="Var gifter ni er?"
                        value={props.wizardData.location || ""}
                        handleChange={(value) => props.setWizardData({ ...props.wizardData, location: value })}
                        id="location"
                        name="location"
                        theme="LIGHT"
                        placeholder="Tex. Jönköping, Smögen etc"
                    />
                </div>
                <div
                    className={`${styles.disableInputWrapper} ${
                        props.wizardData.dateNotDecided ? styles.disabled : ""
                    }`}
                >
                    <Input
                        label="När är er vigsel?"
                        value={props.wizardData.dateStart || ""}
                        handleChange={(value) => props.setWizardData({ ...props.wizardData, dateStart: value })}
                        id="dateStart"
                        type="datetime-local"
                        name="dateStart"
                        theme="LIGHT"
                        disabled={props.wizardData.dateNotDecided}
                    />
                </div>
                <div>
                    <Switch
                        label="Vi har inte bestämt datum än"
                        value={props.wizardData.dateNotDecided || false}
                        handleChange={handleChangeDateNotDecided}
                        id="dateNotDecided"
                        name="dateNotDecided"
                        labelPosition="RIGHT"
                    />
                </div>
                <div className={styles.firstButtonWrapper}>
                    <LinkButton fullWidth={true} type="primary" to={"/kom-igang/sprak"}>
                        Nästa
                    </LinkButton>
                </div>
                <div>
                    <LinkButton fullWidth={true} type="secondary" to={"/kom-igang/sprak"}>
                        Hoppa över frågor
                    </LinkButton>
                </div>
            </div>
        </div>
    );
}
