import { useEffect, useState } from "react";
import { LinkButton } from "../../../../components/button/LinkButton";
import {
    IDesignTemplate,
    selectableDesignTemplates,
} from "../../../../modules/EditDesignTemplate/utils/designTemplates";
import { IWizardData } from "../../StartWizard";
import { WizardTopText } from "../WizardTopText/WizardTopText";
import { WizardDesignTemplateItem } from "./components/EditDesignTemplateItem/WizardDesignTemplateItem";
import styles from "./WizardSelectDesign.module.scss";
import { QuickStartAlreadyLoggedInDialog } from "../../../../components/QuickStartAlreadyLoggedInDialog/QuickStartAlreadyLoggedInDialog";
import Axios from "axios";

interface IProps {
    wizardData: IWizardData;
    setWizardData: (data: IWizardData) => void;
}

export function WizardSelectDesign(props: IProps) {
    const [quickStartAlreadyLoggedInSiteId, setQuickStartAlreadyLoggedInSiteId] = useState<null | string>(null);

    useEffect(() => {
        const getAlreadyLoggedInUser = async () => {
            try {
                const response = await Axios.get(process.env.REACT_APP_API_URL + "/api/accounts/me/");
                setQuickStartAlreadyLoggedInSiteId(response.data.site._id);
            } catch (error) {
                // Do nothing, user not logged in
            }
        };
        getAlreadyLoggedInUser();
    }, []);

    const setDesignTemplate = (designTemplate: IDesignTemplate) => {
        props.setWizardData({ ...props.wizardData, designTemplate });
    };

    return (
        <div className={styles.wrapper}>
            {quickStartAlreadyLoggedInSiteId ? (
                <QuickStartAlreadyLoggedInDialog
                    siteId={quickStartAlreadyLoggedInSiteId}
                    handleClose={() => setQuickStartAlreadyLoggedInSiteId(null)}
                />
            ) : null}
            <div className={styles.grid}>
                <WizardTopText title="Välj designmall" step={1}>
                    Kom igång genom att välja en av våra designmallar.{" "}
                    <strong>Ni kan enkelt ändra ert val senare</strong> och anpassa designens typsnitt, grafik, färger
                    och layout ännu mer.
                </WizardTopText>
                <div className={styles.section}>
                    <div className={styles.designTemplates}>
                        {selectableDesignTemplates.map((designTemplate) => (
                            <WizardDesignTemplateItem
                                selectedDesignTemplate={props.wizardData.designTemplate}
                                key={designTemplate.name}
                                setDesignTemplate={setDesignTemplate}
                                designTemplate={designTemplate}
                            />
                        ))}
                    </div>
                    <div>
                        <LinkButton fullWidth={false} type="secondary" to={"/kom-igang/information"}>
                            Hoppa över, jag vill välja senare
                        </LinkButton>
                    </div>
                </div>
            </div>
        </div>
    );
}
