import { useState } from "react";
import Button from "../../../../components/button/Button";
import { LinkButton } from "../../../../components/button/LinkButton";
import { IWizardData } from "../../StartWizard";
import styles from "./WizardLanguages.module.scss";
import { WizardTopText } from "../WizardTopText/WizardTopText";
import { WizardEditLanguages } from "./components/WizardEditLanguages/WizardEditLanguages";
import { ILanguage } from "../../../../interfaces/Site";

interface IProps {
    wizardData: IWizardData;
    setWizardData: (data: IWizardData) => void;
}

export function WizardLanguages(props: IProps) {
    const [showSelectLanguage, setShowSelectLanguage] = useState(false);

    const setOnlySwedish = () => {
        props.setWizardData({ ...props.wizardData, languages: [{ languageCode: "sv" }] });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.grid}>
                <WizardTopText title="Flera språk?" step={3}>
                    Vill ni ha er bröllopshemsida på flera språk? Ni kan lägga till och ta bort språk när som helst
                    efteråt.
                </WizardTopText>
                {showSelectLanguage ? (
                    <div className={styles.section}>
                        <h3 className={styles.sectionTitle}>Vilka språk vill ni ha?</h3>
                        <WizardEditLanguages
                            availableLanguages={props.wizardData.languages}
                            setAvailableLanguages={(languages: ILanguage[]) =>
                                props.setWizardData({ ...props.wizardData, languages })
                            }
                        />

                        <LinkButton fullWidth={true} type="primary" to={"/kom-igang/innehall"}>
                            Fortsätt
                        </LinkButton>
                    </div>
                ) : (
                    <div className={styles.section}>
                        <div>
                            <LinkButton
                                fullWidth={true}
                                onClick={setOnlySwedish}
                                type="primary"
                                to={"/kom-igang/innehall"}
                            >
                                Bara svenska
                            </LinkButton>
                        </div>
                        <div>
                            <Button
                                type="primary"
                                callback={() => setShowSelectLanguage(true)}
                                buttonText="Flera språk"
                                fullWidth={true}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
